<template>
	<div>
		<header class="modal-card-head">
			<h4 class="modal-card-title">
				<span>
					Qual critério
					<strong>deseja avaliar?</strong>
				</span>
			</h4>
		</header>
		<div class="modal-card-body modal-select modal-rounded">
			<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
			<div v-for="c in categories" :key="c.id" v-show="verifyInactiveFields(c.groups)">
				<span class="modal-select__name mt-2">{{ c.name }}</span>
				<ul>
					<li v-for="g in c.groups" :key="g.id" v-show="g.active">
						<div v-if="!permissionCriteriaManager(g)" class="modal-select__item category">
							<b-tooltip class="locked-overlay" label="Esse critério está bloqueado" position="is-top">
								<svg-icon icon="roles"></svg-icon>
							</b-tooltip>
							<span class="control-label">{{ g.name }}</span>
						</div>
						<a v-else class="modal-select__item category" :class="{ done: g.all_dealers_rated }" @click="openRating(c, g)">
							<span class="control-label">{{ g.name }}</span>
							<b-tooltip :label="g.all_dealers_rated ? 'Avaliado' : 'Aguardando'" type="is-primary" position="is-left">
								<b-icon :icon="g.all_dealers_rated ? 'check' : 'clipboard-text-play-outline'"></b-icon>
							</b-tooltip>
						</a>
					</li>
				</ul>
			</div>
			<!--
			<li class="modal-select__item category" v-for="c in categories" :key="c.id">
				<span class="modal-select__name">{{ c.name }}</span>
				<label class="modal-select__item checkbox" v-for="g in c.groups" :key="g.id" :class="{ checked: g.selected }">
					<b-checkbox v-model="g.selected" type="is-light" size="is-small">{{ g.name }}</b-checkbox>
					<b-tooltip :label="label(g.status)" type="is-primary" position="is-left">
						<b-icon :icon="icon(g.status)"></b-icon>
					</b-tooltip>
				</label>
			</li>
			-->
		</div>
		<!--
		<footer class="modal-card-foot">
			<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">Fechar</b-button>
			<b-button class="is-rounded is-secondary" :disabled="invalid">Avaliar</b-button>
		</footer>
		-->
	</div>
</template>

<script>
import Icon from '@/components/Icon'
import eventHub from '@/services/eventHub'
import { mapGetters } from 'vuex'
import { errorToast } from '@/mixins/toast'
import Api from '@/services/api'

export default {
	components: {
		'svg-icon': Icon
	},
	props: {
		id: {
			type: [Number, String],
			required: false
		},
		// categories: {
		// 	type: [Array],
		// 	required: true
		// },
		user: {}
	},
	data() {
		return {
			isOpening: false,
			invalid: false,
			labels: {
				waiting: 'Aguardando',
				accepted: 'Avaliado'
			},
			icons: {
				waiting: 'clipboard-text-play-outline',
				accepted: 'check'
			},
			categories: []
		}
	},
	computed: {
		...mapGetters('user', [])
	},
	methods: {
		label(labelName) {
			return this.labels[labelName]
		},
		icon(iconName) {
			return this.icons[iconName]
		},
		openRating(group, criteria) {
			this.$emit('close')
			eventHub.$emit('open-rating', { ratingId: this.id, criteriaId: criteria.id, groupId: group.id })
		},
		verifyInactiveFields(group) {
			let status = false
			group.forEach(element => {
				if (Boolean(element.active) === true) {
					status = true
				}
			})

			return status
		},
		async getCategoriesRating() {
			try {
				this.isOpening = true
				const response = await Api.get(`rating/find-criterias/${this.id}`)
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.categories = data
					this.isOpening = false
				} else {
					errorToast('Erro ao listar as categorias')
				}
			} catch (e) {
				errorToast('Erro ao listar as categorias')
			} finally {
				this.isOpening = false
			}
		},
		permissionCriteriaManager(criteria) {
			if (this.user && this.user.role.name == 'manager') {
				let perm = this.user.criterias.find((c) => c.id == criteria.original_id)

				if (!perm) {
					return false
				}
			}

			return true
		}
	},
	mounted() {
		this.getCategoriesRating()
	}
}
</script>
