<template>
	<ValidationObserver ref="observer" v-slot="{ invalid, handleSubmit }">
		<form @submit.prevent="handleSubmit(sendRating)">
			<header class="modal-card-head">
				<h4 class="modal-card-title is-flex is-justify-content-space-between">
					<span>
						Avaliar:
						<strong class="is-capitalized" v-if="criteriaId">{{ criteria ? criteria.name : '' }}</strong>
						<strong class="is-capitalized" v-else-if="billing">Faturamento</strong>
						<strong class="is-capitalized" v-else>{{ dealer.name || '' }}</strong>
					</span>
				</h4>
				<b-button v-if="dealerId" class="modal-card-title-button is-rounded is-outlined is-secondary" :loading="loadingExport" @click="exportRating">Exportar</b-button>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="columns is-mobile mb-4" v-if="!criteriaId && !billing">
					<div class="column is-8-mobile" :class="{ 'is-5-tablet': typeOfUser() !== 'user' }">
						<p class="is-size-5 has-text-dark" v-if="typeOfUser() === 'user'">{{ rating.message ? rating.message : 'Nenhuma observação adicionada.' }}</p>
						<InputWithValidation v-else type="text" :disabled="rating.status.name === 'accepted'" label="Observações" size="is-medium" placeholder="Algum detalhe sobre o dealer?" v-model="rating.message" />
						<a @click="downloadFilesPolicy()" class="is-block has-text-secondary" target="_blank" rel="noopener noreferrer" download>Baixar a política de bônus</a>
					</div>
					<div class="column is-12-mobile is-4-tablet">
						<b-field label="Faturamento" v-if="typeOfUser() !== 'user'">
							<input class="input" type="text" v-model.lazy="rating.billing" v-money="money" :disabled="rating.status.name === 'accepted' || ratingFinished() || typeOfUser() === 'user'">
						</b-field>
						<span v-if="typeOfUser() === 'user' && calculationBonus() > 0">
							<strong class="modal-payment__title">{{ 'Faturamento' }}</strong>
							<input class="input" type="text" :value="rating.billing" v-money="money" :disabled="true">
						</span>
					</div>
					<div class="column is-4-mobile is-3-tablet is-flex is-justify-content-end">
						<span v-if="rating.past_total > 0" class="modal-score past" :class="'background-' + color(rating.past_total)">
							{{ rating.past_total }}
							<small>Anterior</small>
						</span>
						<span class="modal-score" :class="'background-' + color(rating.total)">
							{{ rating.total }}
							<small>Atual</small>
						</span>
					</div>
				</div>

				<div v-if="criteriaId">
					<h4 class="modal-groups__group first">
						<span>Dealers</span>
					</h4>
					<div class="columns is-multiline mt-1 mb-5" v-if="criteria">
						<div v-for="d in dealers" :key="d.id" :title="d.name" class="modal-groups__item column is-12-tablet" :class="{ active: d.invalid != true && isValid(d.rate), error: d.invalid }">
							<span class="modal-groups__name">
								<span class="is-block is-ellipsis">{{ d.name }}</span>
							</span>
							<b-tooltip type="is-primary" position="is-top" class="help points">
								<template v-slot:content>
									<strong>Pontuação</strong>
									<span v-html="getLabelEquivalence(criteria)"></span>
								</template>
								<span class="modal-groups__points">{{ getWeight(criteria) }}</span>
							</b-tooltip>

							<InputWithValidation
								class="modal-groups__rate adjust-width-contest"
								:class="{ 'has-equivalence': hasEquivalenceVariable(criteria) || hasPointHistory(d) }"
								name="test"
								:rules="rateValidation(rating, criteria)"
								minlength="1"
								maxlength="6"
								type="text"
								size="is-medium"
								v-model="d.rate"
								v-mask="rateMask(criteria)"
								@change.native="fieldValidation(rating, criteria, d)"
								:disabled="d.status === 'accepted' || typeOfUser() === 'user'">
								<span v-if="hasEquivalenceVariable(criteria) || hasPointHistory(d)" class="equivalence is-medium">
									<span v-if="hasPointHistory(d)" class="points-history">{{ showLastNoteHistory(d.historic) }}</span>
									{{ calculateEquivalenceVariable(d.rate, criteria.equivalence, criteria.equivalences) }}
								</span>
							</InputWithValidation>

							<b-input class="modal-groups__notes comment" placeholder="Alguma informação adicional?" type="text" v-model="d.notes" :disabled="d.status === 'accepted' || typeOfUser() === 'user'"></b-input>
						</div>
					</div>
				</div>

				<div v-else-if="billing">
					<h4 class="modal-groups__group first">
						<span>Dealers</span>
					</h4>
					<div class="columns is-multiline mt-1 mb-5">
						<div v-for="d in dealers" :key="d.id" :title="d.name" class="modal-groups__item column is-12-tablet" :class="{ active: d.billing.split('').length >= 10 }">
							<span class="modal-groups__name large">
								<span class="is-block is-ellipsis">{{ d.name }}</span>
							</span>
							<b-field>
								<b-input v-if="typeOfUser() !== 'user'" type="text" v-model="d.billing" v-money="money" :disabled="rating.status.name === 'accepted' || ratingFinished() || typeOfUser() === 'user'"></b-input>
							</b-field>
						</div>
					</div>
				</div>

				<div v-else>
					<div v-for="g in rating.groups" :class="{ 'modal-groups': verifyInactiveFields(g.groups) }" v-show="verifyInactiveFields(g.groups)" :key="g.id">
						<h4 class="modal-groups__group mt-5" v-if="verifyInactiveFields(g.groups)">
							<span>{{ g.name }}</span>
						</h4>
						<div class="columns is-multiline mt-1 mb-5">
							<div v-for="c in g.groups" :key="c.id" class="modal-groups__item column is-12-tablet" v-show="c.active" :class="{ active: c.invalid != true && isValid(c.rate), error: c.invalid || c.equivalence == 0 && getWeight(c) < Number(c.rate), contested: c.contest }">
								<span class="modal-groups__name" :class="{ small: rating.status.name === 'rated' || rating.status.name === 'contested' || rating.status.name === 'accepted' }">
									<span class="is-block is-ellipsis">{{ c.name }}</span>
								</span>
								<b-tooltip type="is-primary" position="is-top" class="help points">
									<template v-slot:content>
										<strong>Pontuação</strong>
										<span v-html="getLabelEquivalence(c)"></span>
									</template>
									<span class="modal-groups__points">{{ getWeight(c) }}</span>
								</b-tooltip>
								<span v-if="typeOfUser() === 'user'" class="modal-groups__points voted mx-2">{{ c.value }}</span>
								<InputWithValidation
									v-else-if="c.active && !permissionCriteriaManager(c)"
									:disabled="true"
									class="modal-groups__rate adjust-width-contest"
									type="text"
									size="is-medium"
									v-model="c.rate">
									<span v-if="hasEquivalenceVariable(c) || hasPointHistory(c)" class="equivalence is-medium" :class="{ 'clickable': hasPointHistory(c) }" @click="viewPointsHistory(c.name, c.historic)">
										<span v-if="hasPointHistory(c)" class="points-history">{{ showLastNoteHistory(c.historic) }}</span>
										<span v-if="hasEquivalenceVariable(c)">
											{{ calculateEquivalenceVariable(c.rate, c.equivalence, c.equivalences) }}
										</span>
									</span>
								</InputWithValidation>
								<InputWithValidation
									v-else-if="c.active"
									:disabled="rating.status.name === 'accepted' || ratingFinished()"
									class="modal-groups__rate adjust-width-contest"
									:class="{ 'has-equivalence': hasEquivalenceVariable(c) || hasPointHistory(c) }"
									:rules="rateValidation(rating, c)"
									minlength="1"
									maxlength="6"
									type="text"
									size="is-medium"
									v-model="c.rate"
									v-mask="rateMask(c)"
									@change.native="getScore(), fieldValidation(rating, c)">
									<span v-if="hasEquivalenceVariable(c) || hasPointHistory(c)" class="equivalence is-medium" :class="{ 'clickable': hasPointHistory(c) }" @click="viewPointsHistory(c.name, c.historic)">
										<span v-if="hasPointHistory(c)" class="points-history">{{ showLastNoteHistory(c.historic) }}</span>
										<span v-if="hasEquivalenceVariable(c)">
											{{ calculateEquivalenceVariable(c.rate, c.equivalence, c.equivalences) }}
										</span>
									</span>
								</InputWithValidation>

								<b-input class="modal-groups__notes comment mr-2" v-if="typeOfUser() === 'user'" placeholder="Sem comentários do manager" disabled type="text" v-model="c.notes"></b-input>
								<b-input class="modal-groups__notes comment" v-else-if="c.active" :disabled="rating.status.name === 'accepted' || ratingFinished()" placeholder="Alguma informação adicional?" type="text" v-model="c.notes"></b-input>

								<button type="button" class="modal-groups__contest" v-if="c.contests" :class="{ contested: c.contests.length > 0 }" @click="openContestation(c.id)">{{ c.contests.length > 0 ? c.contests.length : '' }}</button>
								<b-tooltip v-if="!permissionCriteriaManager(c)" class="locked-overlay" label="Esse critério está bloqueado, pois será avaliado por outra pessoa" position="is-top">
									<svg-icon icon="roles"></svg-icon>
								</b-tooltip>
							</div>
						</div>
					</div>
				</div>

				<div class="modal-groups modal-payment pb-3" v-if="typeOfUser() === 'user' || (typeOfUser() === 'master' && rating.status.name === 'accepted')">
					<h4 class="modal-payment__title">
						<span>Nota de Débito</span>
					</h4>
					<div class="columns is-multiline mt-0">
						<div class="column is-12-tablet" v-if="rating.status.name === 'accepted'">
							<div v-if="dealerGroup">
								<span class="large-text" v-if="calculationBonus() > 0 || dealerGroup && totalGroupBonus > 0">A nota de débito foi <strong>enviada pelo dealer</strong>: {{ dealerUploadNf }}.</span>
								<table class="table is-fullwidth modal-table">
									<thead>
										<tr>
											<th>Dealer</th>
											<th>Bônus</th>
										</tr>
									</thead>
									<tbody>
										<tr class="is-selected">
											<th>{{ dealer.name }}</th>
											<td>{{ rating.total }}</td>
										</tr>
										<tr v-for="d in dealerGroup" :key="d.id">
											<th>{{ d.name }}</th>
											<td v-html="d.rate ? d.rate.total : 'Não avaliada'"></td>
										</tr>
										<tr>
											<th>Média do Grupo</th>
											<td>
												<b-tooltip :label="colorLabel(groupAverage)" position="is-top">
													<span>{{ groupAverage }}</span>
													<svg-icon icon="star" :class="color(groupAverage)"></svg-icon>
												</b-tooltip>
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<th>Total</th>
											<td v-html="billingBonusGroup()"></td>
										</tr>
									</tfoot>
								</table>
							</div>
							<p class="has-text-grey-dark" v-else>
								<span v-if="calculationBonus() > 0 || dealerGroup && totalGroupBonus > 0">A nota de débito foi <strong>enviada</strong> pelo dealer, o valor do bônus é de</span>
								<strong class="pl-1" v-html="billingBonus()"></strong>
							</p>
							<a v-if="calculationBonus() > 0 || dealerGroup && totalGroupBonus > 0" @click="downloadFiscalNote()" class="mt-2 mb-5 button is-primary" rel="noopener noreferrer" download :class="{ 'is-loading': downloading }">Download</a>
							<svg-icon class="icon modal-payment__icon" icon="code" />
						</div>
						<div class="column is-12-tablet mb-1 pt-0" v-else>
							<p class="large-text">Confira a pontuação de cada critério, faça upload da nota de débito com o valor do bônus e conclua a avaliação.</p>
							<div v-if="dealerGroup">
								<table class="table is-fullwidth modal-table">
									<thead>
										<tr>
											<th>Dealer</th>
											<th>Bônus</th>
										</tr>
									</thead>
									<tbody>
										<tr class="is-selected">
											<th>{{ dealer.name }}</th>
											<td>{{ rating.total }}</td>
										</tr>
										<tr v-for="d in dealerGroup" :key="d.id">
											<th>{{ d.name }} {{ (d.rate && d.rate.status.name == 'contested') ? '(Em contestação)' : '' }}</th>
											<td v-html="d.rate ? d.rate.total : 'Não avaliada'"></td>
										</tr>
										<tr>
											<th>Média do Grupo</th>
											<td>
												<b-tooltip :label="colorLabel(groupAverage)" position="is-top">
													<span>{{ groupAverage }}</span>
													<svg-icon icon="star" :class="color(groupAverage)"></svg-icon>
												</b-tooltip>
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<th>Total</th>
											<td v-html="billingBonusGroup()"></td>
										</tr>
									</tfoot>
								</table>
							</div>
							<p v-else>
								<strong>Valor do Bônus:</strong>
								<span class="pl-1" v-html="billingBonus()"></span>
							</p>
							<div class="modal-payment__field field" v-if="calculationBonus() > 0 || dealerGroup && totalGroupBonus > 0 && rating.rating_status !== 'finished'">
								<div class="notification is-danger" v-if="dealerGroup && !groupRated">
									Para liberar o envio da nota fiscal, é necessário que <strong>todos os dealers</strong> do grupo estejam avaliados.
								</div>
								<div v-else class="control input is-medium fake">
									<div class="files">
										{{ rating.files.name !== undefined ? rating.files.name.substring(0, 40) + '...' : '' }}
									</div>
									<b-upload class="button is-secondary" v-model="rating.files" ref="files">Adicionar</b-upload>
								</div>
							</div>
							<svg-icon class="icon modal-payment__icon" icon="code" />
						</div>
					</div>
				</div>
				
				<div v-if="rating.status.name == 'accepted' && (calculationBonus() > 0 || dealerGroup && totalGroupBonus > 0)" class="modal-groups modal-payment modal-payment--status mt-4 mb-5">
					<h4 class="modal-payment__title">
						<span>Status do Pagamento</span>
					</h4>
					<p class="large-text my-3">Altere o <strong>status do pagamento</strong> dessa nota para informar o dealer.</p>
					<div class="columns">
						<div class="column">
							<b-select placeholder="Aguardando Pagamento" :loading="false" v-model="statusPayment" :disabled="typeOfUser() != 'master' || rating.status_payment == 'payment_done'">
								<option value="waiting_payment">Enviado para Pagamento</option>
								<option value="payment_done">Pagamento Realizado</option>
							</b-select>
						</div>
						<div class="column has-text-right">
							<b-upload v-if="typeOfUser() == 'master' && rating.status_payment != 'payment_done' && statusPayment == 'payment_done'" class="button is-success" v-model="notePayFile" ref="notePay">Anexar o Comprovante</b-upload>
							<a v-if="rating.note_payment" :href="rating.note_payment" class="button is-success">Download do Comprovante</a>
						</div>
					</div>
					<svg-icon class="icon modal-payment__icon" icon="receipt" />
				</div>
			</div>
			<footer class="modal-card-foot">
				<div>
					<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">Fechar</b-button>
				</div>
				<div v-if="!isOpening && !loading && rating.rating_status && rating.rating_status !== 'finished'">
					<div v-if="typeOfUser() === 'user'">
						<div v-if="dealerGroup">
							<b-button v-if="rating.status.name !== 'accepted' && statusGroup !== 'contested' && rating.rating_status !== 'finished'" class="is-rounded is-primary" :loading="loading" :disabled="concludeDisabled()" @click="accept()">Concluir</b-button>
							<b-button v-if="statusGroup == 'contested' && rating.rating_status !== 'finished'" class="is-rounded is-primary" @click="$emit('close')">Finalizar</b-button>
						</div>
						<div v-else>
							<b-button v-if="rating.status.name !== 'accepted' && rating.status.name !== 'contested' && rating.rating_status !== 'finished'" class="is-rounded is-primary" :loading="loading" :disabled="concludeDisabled()" @click="accept()">Concluir</b-button>
							<b-button v-if="rating.status.name == 'contested' && rating.rating_status !== 'finished'" class="is-rounded is-primary" @click="$emit('close')">Finalizar</b-button>
						</div>
					</div>
					<div v-else>
						<b-button v-if="rating.status.name !== 'accepted' && rating.rating_status !== 'finished' && buttonSaveEnabled" native-type="submit" class="is-rounded is-primary" :class="{ 'is-loading': loading }" :disabled="invalid">{{ billing ? 'Atualizar' : 'Avaliar' }}</b-button>
					</div>
					<div v-if="typeOfUser() === 'master' && (calculationBonus() > 0 || dealerGroup && totalGroupBonus > 0)">
						<b-button v-if="rating.status.name == 'accepted' && rating.status_payment != 'payment_done'" class="is-rounded is-primary" :class="{ 'is-loading': loading }" @click="updatePayment()">Atualizar Pagamento</b-button>
					</div>
				</div>
			</footer>
		</form>
		<span v-if="!isOpening && !loading && rating && ['master', 'manager'].includes(typeOfUser()) && dealerId">
			<button class="modal-button left" :disabled="currentIndexDealer == 0" @click="nextOrPreviousRate('previous')">
				<svg-icon icon="right"></svg-icon>
			</button>
			<button class="modal-button right" :disabled="currentIndexDealer == maxIndexDealer" @click="nextOrPreviousRate('next')">
				<svg-icon icon="right"></svg-icon>
			</button>
		</span>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import Icon from '@/components/Icon'
import Chat from '@/components/modals/Chat'
import Points from '@/components/modals/Points'
import { VMoney } from 'v-money'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import { ModalProgrammatic as Modal } from 'buefy'
import { validate } from 'vee-validate'
import '@/mixins/generic'
import { mapState } from 'vuex'

export default {
	components: {
		InputWithValidation,
		ValidationObserver,
		'svg-icon': Icon
	},
	directives: {
		money: VMoney
	},
	props: {
		id: {
			type: [Number, String],
			required: false
		},
		dealerId: {
			type: [Number, String],
			required: false
		},
		criteriaId: {
			type: [Number, String],
			required: false
		},
		groupId: {
			type: [Number, String],
			required: false
		},
		user: {},
		billing: {
			type: Boolean,
			required: false
		},
		currentIndexDealer: {
			type: [Number, String],
			required: false
		},
		maxIndexDealer: {
			type: [Number, String],
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			downloading: false,
			money: {
				decimal: ',',
				thousands: '.',
				prefix: 'R$ ',
				precision: 2
			},
			rating: {
				past_total: 0,
				total: 0,
				billing: '0',
				files: [],
				groups: [].length,
				status: '',
				bonus: 0
			},
			dealer: {
				name: ''
			},
			dealerGroup: null,
			dealers: [],
			criteria: null,
			buttonSaveEnabled: true,
			statusPayment: null,
			notePayment: null,
			notePayFile: null,
			ratingBase: null,
			rateSaved: false,
			loadingExport: false
		}
	},
	methods: {
		getLabelEquivalence(c) {
			let texto = ''

			if (c.equivalence == 0) {
				texto = 'Padrão'
			} else if (c.equivalence == 1) {
				c.equivalences.forEach((e, i) => {
					if (i > 0) texto += '<br> '
					texto += `${e.from} = ${e.index}`
				})
			} else {
				c.equivalences.forEach((e, i) => {
					if (i > 0) texto += '<br> '
					texto += `${e.from} a ${e.to} = ${e.index}`
				})
			}

			return texto
		},
		isValid(v) {
			return v !== null && v !== undefined ? v.length > 0 : false
		},
		rateMask(c) {
			let e = c.equivalence
			return e == 0 ? '####' : ''
		},
		rateValidation(r, c) {
			let n = r.status.name,
				req = n === 'waiting' || n === 'require_payment' || n === undefined ? 'required' : '',
				type = c.equivalence == 0 ? '|numeric' : c.equivalence == 1 ? '|alpha|max:4' : ''

			return `${req}${type}`
		},
		fieldValidation(r, c, d) {
			if (d) {
				d.invalid = false
				validate(d.rate, this.rateValidation(r, c)).then(result => {
					d.invalid = result.valid ? false : true
				})
			} else {
				validate(c.rate, this.rateValidation(r, c)).then(result => {
					c.invalid = result.valid ? false : true
					// console.log(d.rate)
					// console.log('invalid', c.invalid)
					// console.log('result', c.rate, result)
				})
			}
		},
		ratingFinished() {
			return this.rating.rating_status === 'finished'
		},
		concludeDisabled() {
			return (this.calculationBonus() > 0 || this.dealerGroup && this.totalGroupBonus > 0) && this.rating.files.length == 0
		},
		typeOfUser() {
			return this.user.role.name
		},
		verifyInactiveFields(group) {
			let status = false
			group.forEach(element => {
				if (Boolean(element.active) === true) {
					status = true
				}
			})

			return status
		},
		downloadFile(file) {
			Api.post(`rating/download`, { file: file }, { responseType: 'blob' }).then(response => {
				let blob = new Blob([response.data], { type: response.data.type })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				let filename = file.split('/')
				link.download = filename[filename.length - 1]
				link.click()
			})
		},
		getFloatFromString(value) {
			let billing = value
			if (value.includes('R$')) {
				billing = billing.replace('R$ ', '').replaceAll('.', '').replace(',', '.')
			}

			return parseFloat(billing || '0')
		},
		calculationBonus() {
			let percent = 0
			const total = this.rating.total
			const ratingBonus = this.rating.bonus / 100

			if (total >= 88) {
				percent = 1.0
			} else if (total >= 80 && total <= 87) {
				percent = 0.9
			} else if (total >= 70 && total <= 79) {
				percent = 0.8
			}

			let billing = this.getFloatFromString(this.rating.billing)
			let bonus = billing * ratingBonus * percent

			return bonus
		},
		billingBonus() {
			let bonus = this.calculationBonus()

			if (bonus == 0) {
				return 'Você <strong>não atingiu</strong> a pontuação necessária para receber o bônus.'
			}

			return bonus.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
		},
		billingBonusGroup() {
			let bonusGroup = this.totalGroupBonus

			if (bonusGroup == 0) {
				return '<strong>Não atingiu</strong> a pontuação necessária para receber o bônus.'
			}

			return bonusGroup.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
		},
		downloadFilesPolicy() {
			Api.get(`rating/get-array-policy-files/${this.id}`).then(response => {
				const { data } = response
				data.forEach(file => {
					this.downloadFile(file)
				})
			})
		},
		downloadFiscalNote() {
			this.downloading = true
			Api.get(`rating/get-fiscal-notes-files/${this.rating.id}`).then(response => {
				const { data } = response
				data.forEach(file => {
					this.downloadFile(file)
					this.downloading = false
				})
			})
		},
		getWeight(criteria) {
			let weight = 0

			if (criteria.equivalence == 0) {
				weight = criteria.weight
			} else {
				let eq = [...criteria.equivalences]
				weight = eq.pop().index
			}

			return parseInt(weight)
		},
		getScore() {
			this.rating.total = 0
			let r = this.rating,
				g = r.groups

			for (var i in g) {
				for (var c in g[i].groups) {
					let rate = parseInt(g[i].groups[c].rate)

					if (g[i].groups[c].rate) {
						if (g[i].groups[c].equivalence == 1) {
							rate = 0
							g[i].groups[c].equivalences.forEach(e => {
								if (e.from == g[i].groups[c].rate.toUpperCase()) {
									rate = parseInt(e.index)
								}
							})
						} else if (g[i].groups[c].equivalence == 2) {
							rate = 0
							g[i].groups[c].equivalences.forEach(e => {
								if (this.nBetween(g[i].groups[c].rate, e.to, e.from)) {
									rate = parseInt(e.index)
								}
							})
						}
					}

					r.total += rate ? rate : 0
				}
			}
		},
		nBetween(varToCheck, high, low) {
			varToCheck = parseFloat(varToCheck.replaceAll(',', '.'))

			if (isNaN(high) || isNaN(low)) return false
			if (isNaN(varToCheck)) return false
			if (varToCheck < parseFloat(low)) return false
			if (varToCheck > parseFloat(high)) return false

			return true
		},
		async getAllCategories() {
			try {
				this.isOpening = true
				const response = await Api.get('criteria-group/findAll')
				const { status } = response

				if (status === 200) {
					this.isOpening = false
					this.getScore()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.isOpening = false
			}
		},
		async sendRating() {
			if (this.criteriaId) {
				await this.storeCategories()
			} else if (this.billing) {
				await this.storeBilling()
			} else if (this.typeOfUser() === 'user') {
				await this.contest()
			} else {
				await this.store()
			}
		},
		async contest() {
			try {
				this.loading = true
				const send = { ...this.rating, contest: true }
				const response = await Api.put(`rating/rate/${this.id}/${this.dealerId}`, send)

				const { status } = response

				if (status === 200) {
					this.closeAll('Avaliação <strong>contestada</strong> com sucesso!')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast('Ocorreu um <strong>erro</strong> ao avaliar esse dealer!')
				}
			} finally {
				this.loading = false
			}
		},
		async accept() {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: 'Atenção',
				message: '<span>Você realmente deseja <br><strong>concluir</strong> esta avaliação?</span> <small>Não vai ser mais possível contestar.</small>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: 'Não',
				confirmText: 'Sim',
				onConfirm: async () => {
					try {
						this.loading = true
						const bonus = this.calculationBonus()
						if ((bonus > 0 || this.dealerGroup && this.totalGroupBonus > 0) && this.rating.files.length === 0) {
							errorToast('Necessário <strong>anexar</strong> nota fiscal!')
							return false
						}

						const response = await Api.put(`rating/rate/accept/${this.id}/${this.dealerId}`)
						const { status } = response
						const dealerRate = response.data.dealerRate
						if (status === 200) {
							if (this.typeOfUser() === 'user' && (bonus > 0 || this.dealerGroup && this.totalGroupBonus > 0)) {
								const formData = new FormData()
								formData.append(`files[]`, this.$refs.files.value)
								formData.append('dealerRate', dealerRate)
								await Api.post(`rating/upload-fiscal-note`, formData)
							}
							this.$emit('close')
							history.pushState({}, '', '/ratings')
							successToast('Avaliação <strong>concluída</strong> com sucesso!')
							eventHub.$emit('reload-ratings')
						}
					} catch (e) {
						const { status } = e
						if (status === 422) {
							errorToast('Ocorreu um <strong>erro</strong> ao avaliar esse dealer!')
						}
					} finally {
						this.loading = false
					}
				}
			})
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.put(`rating/rate/${this.id}/${this.dealerId}`, this.rating)
				const { status } = response
				if (status === 200) {
					this.rateSaved = true
					this.findById()
					successToast('O dealer foi <strong>avaliado</strong> com sucesso!')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast('Ocorreu um <strong>erro</strong> ao avaliar esse dealer!')
				}
			} finally {
				this.loading = false
			}
		},
		async storeCategories() {
			try {
				this.loading = true
				this.isOpening = true
				const response = await Api.put(`rating/rate-criteria/${this.id}`, { dealers: this.dealers, criteria: this.criteria })
				const { status } = response
				if (status === 200) {
					this.closeAll('Os dealers foram <strong>avaliados</strong> com sucesso!')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast('Ocorreu um <strong>erro</strong> ao avaliar os dealers!')
				}
			} finally {
				this.loading = false
				this.isOpening = false
			}
		},
		async storeBilling() {
			try {
				this.loading = true
				const response = await Api.put(`rating/rate-billing/${this.id}`, this.dealers)
				const { status } = response
				if (status === 200) {
					this.closeAll('Os dealers foram <strong>avaliados</strong> com sucesso!')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast('Ocorreu um <strong>erro</strong> ao avaliar os dealers!')
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			this.rateSaved = false
			this.isOpening = true
			try {
				const response = await Api.get(`rating/findByIdWithDealer/${this.id}/${this.criteriaId ? 0 : this.dealerId}`)
				const { status } = response

				if (status === 200) {
					const { data } = response

					if (this.criteriaId) {
						data.groups.forEach(g => {
							g.groups.forEach(c => {
								if (this.criteriaId == c.id) {
									this.criteria = c
								}
							})
						})
					}

					this.rating.groups = data.groups
					this.dealerGroup = data.dealerGroup
					this.rating = { ...this.rating, ...data.rate, name: data.name, bonus: data.bonus, rating_status: data.status_name }
					this.statusPayment = this.rating.status_payment
					this.getScore()

					this.ratingBase = JSON.parse(JSON.stringify(this.rating))
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.isOpening = false
			}
		},
		async findByDealerId() {
			this.isOpening = true
			try {
				const response = await Api.get(`dealer/findById/${this.dealerId}`)
				this.dealer = response.data
			} catch (e) {
				console.log(e)
			} finally {
				this.isOpening = false
			}
		},
		async getAllDealers() {
			this.isOpening = true
			try {
				const response = await Api.get(`dealer/get-dealers-with-criteria/${this.id}/${this.criteriaId}/${this.groupId}`)
				const { status } = response

				if (status === 200) {
					this.dealers = response.data
					this.isOpening = false
					this.checkStatusDealers()
				}
			} catch (e) {
				console.log(e)
			}
		},
		async getDealersWithBilling() {
			this.isOpening = true
			try {
				const response = await Api.get(`dealer/get-dealers-with-billing/${this.id}`)
				this.dealers = response.data
				this.isOpening = false
				this.checkStatusDealers()
			} catch (e) {
				console.log(e)
			}
		},
		nextOrPreviousRate(type) {
			const changes = this.unsavedChanges()

			console.log(changes)

			if (changes.unsaved && !this.rateSaved) {
				this.$buefy.dialog.alert({
					size: 'is-delete',
					type: 'is-outlined is-primary',
					title: 'Atenção',
					message: '<span>Existem alterações não salvas, deseja continuar?</span> <small>As alterações serão perdidas.</small>',
					canCancel: true,
					focusOn: 'cancel',
					cancelText: 'Não',
					confirmText: 'Sim',
					onConfirm: async () => {
						this.$emit('close')
						eventHub.$emit(`${type}-dealer`, { currentIndexDealer: this.currentIndexDealer })
					}
				})
			} else {
				this.$emit('close')
				eventHub.$emit(`${type}-dealer`, { currentIndexDealer: this.currentIndexDealer })
			}
		},
		checkStatusDealers() {
			this.buttonSaveEnabled = true
			const pending = this.dealers.filter(d => d.status != 'accepted')

			if (pending.length == 0) {
				this.buttonSaveEnabled = false
			}
		},
		hasEquivalenceVariable(c) {
			if ([1, 2, '1', '2'].includes(c.equivalence)) {
				return true
			}

			return false
		},
		hasPointHistory(rc) {
			if (rc.historic && rc.historic.length > 0) {
				return true
			}

			return false
		},
		showLastNoteHistory(historic) {
			let old = historic.slice(-1)[0]

			return old.note_old
		},
		calculateEquivalenceVariable(rate, equivalence, equivalences) {
			if (rate) {
				let newRate = 0

				if (equivalence == 1) {
					equivalences.forEach(e => {
						if (e.from == rate.toUpperCase()) {
							newRate = parseInt(e.index)
						}
					})
				} else if (equivalence == 2) {
					equivalences.forEach(e => {
						if (this.nBetween(rate, e.to, e.from)) {
							newRate = parseInt(e.index)
						}
					})
				}

				return newRate
			}

			return 0
		},
		openContestation(id) {
			Modal.open({
				parent: this,
				component: Chat,
				scroll: 'keep',
				customClass: 'is-lg',
				trapFocus: true,
				props: { id: id }
			})
		},
		closeAll(message) {
			this.$emit('close')
			history.pushState({}, '', '/ratings')
			successToast(message)
			eventHub.$emit('reload-ratings')
		},
		permissionCriteriaManager(criteria) {
			if (this.user && this.user.role.name == 'manager') {
				let perm = this.user.criterias.find((c) => c.id == criteria.original_id)

				if (!perm) {
					return false
				}
			}

			return true
		},
		async updatePayment() {
			if (this.typeOfUser() === 'master') {
				try {
					this.loading = true
					const formData = new FormData()

					formData.append('dealer_rate_id', this.rating.id)
					formData.append('status_payment', this.statusPayment)

					if (this.notePayFile) {
						formData.append('file', this.notePayFile)
					}

					await Api.post(`rating/update-payment`, formData)

					this.$emit('close')
					history.pushState({}, '', '/ratings')
					successToast('Pagamento <strong>atualizado</strong> com sucesso!')
					eventHub.$emit('reload-ratings')
				} catch (e) {
					console.log(e)
					errorToast('Ocorreu um <strong>erro</strong> ao atualizar o pagamento!')
				} finally {
					this.loading = false
				}
			}
		},
		viewPointsHistory(criteriaName, historic) {
			Modal.open({
				parent: this,
				component: Points,
				scroll: 'keep',
				customClass: 'is-sm',
				trapFocus: true,
				props: { criteriaName: criteriaName, historic: historic }
			})
		},
		unsavedChanges() {
			let changes = []
			let unsaved = false

			if ((this.rating.message ? this.rating.message : '') != (this.ratingBase.message ? this.ratingBase.message : '')) {
				unsaved = true

				changes.push({
					field: 'Observação'
				})
			}

			if (this.getFloatFromString(this.rating.billing) != this.getFloatFromString(this.ratingBase.billing)) {
				unsaved = true

				changes.push({
					field: 'Faturamento'
				})
			}

			this.rating.groups.forEach((g1, i1) => {
				g1.groups.forEach((g2, i2) => {
					const g3 = this.ratingBase.groups[i1].groups[i2]

					if (g2.rate) {
						if (isNaN(g2.rate)) {
							if ((g3.rate ? g3.rate.toUpperCase() : '') != (g2.rate ? g2.rate.toUpperCase() : '')) {
								unsaved = true

								changes.push({
									group: g1,
									criteria: g2
								})
							}
						} else {
							if (g3.rate != g2.rate) {
								unsaved = true

								changes.push({
									group: g1,
									criteria: g2
								})
							}
						}
					}
				})
			})

			return {
				changes,
				unsaved
			}
		},
		exportRating() {
			this.loadingExport = true
			Api.post(`rating/export-rating`, { ratingId: this.id, dealerId: this.dealerId }, { responseType: 'blob' })
				.then((res) => {
					let blob = new Blob([res.data], { type: res.data.type })
					let link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = 'report.xls'
					link.click()
				}).catch((err) => {
					console.log(err)
					errorToast('Ocorreu um <strong>erro</strong> ao exportar a avaliação.')
				}).finally(() => {
					this.loadingExport = false
				})
		}
	},
	mounted() {
		this.findById()
		if (this.dealerId) {
			this.findByDealerId()
		}
		if (this.criteriaId) {
			this.getAllDealers()
		}
		if (this.billing) {
			this.getDealersWithBilling()
		}
		this.getAllCategories()
	},
	watch: {
		async criteriaContest(v) {
			if (v && v.contest) {
				try {
					const response = await Api.get(`rating/findByIdWithDealer/${this.id}/${this.criteriaId ? 0 : this.dealerId}`)
					const { status } = response

					if (status === 200) {
						const { data } = response
						this.rating.status = data.rate.status
					}
				} catch (e) {
					console.log(e)
				}

				this.rating.groups.forEach((g, i1) => {
					g.groups.forEach((c, i2) => {
						if (c.id == v.id) {
							this.rating.groups[i1].groups[i2].contest = v.contest
							this.rating.groups[i1].groups[i2].contests = v.contests
						}
					})
				})
			}
		}
	},
	computed: {
		...mapState('user', [
			'criteriaContest'
		]),
		groupRated() {
			let status = true

			if (this.dealerGroup) {
				this.dealerGroup.forEach((d) => {
					if (!d.rate) {
						status = false
					}
				})
			}

			return status
		},
		totalGroupBonus() {
			let percent = 0
			const total = this.groupAverage
			const ratingBonus = this.rating.bonus / 100

			if (total >= 88) {
				percent = 1.0
			} else if (total >= 80 && total <= 87) {
				percent = 0.9
			} else if (total >= 70 && total <= 79) {
				percent = 0.8
			}

			let billing = this.getFloatFromString(this.rating.billing)

			this.dealerGroup.forEach((d) => {
				if (d.rate) {
					billing += this.getFloatFromString(d.rate.billing)
				}
			})

			let bonus = billing * ratingBonus * percent

			return bonus
		},
		dealerUploadNf() {
			let dealerName = ''

			if (this.dealerGroup) {
				if (this.rating && this.rating.main_nf == 1) {
					dealerName = this.dealer.name
				}

				this.dealerGroup.forEach((d) => {
					if (d.rate && d.rate.main_nf == 1) {
						dealerName = d.name
					}
				})
			}

			return dealerName
		},
		groupAverage() {
			if (!this.dealerGroup) {
				return 0
			}

			let sizeGroup = 1

			let totalAll = this.rating.total

			this.dealerGroup.forEach((d) => {
				sizeGroup++

				if (d.rate) {
					totalAll += d.rate.total
				}
			})

			totalAll = Math.round(totalAll / sizeGroup)

			return totalAll
		},
		statusGroup() {
			let status = ''
			if (!this.dealerGroup) {
				return status
			}

			if (this.rating.status.name == 'contested') {
				status = 'contested'
			}

			this.dealerGroup.forEach((d) => {
				if (d.rate && d.rate.status.name == 'contested') {
					status = 'contested'
				}
			})

			return status
		}
	}
}
</script>
